// Entry point for the build script in your package.json
import { Turbo } from "@hotwired/turbo-rails"
import Rails from "@rails/ujs"
import "./controllers"
import * as bootstrap from "bootstrap"
import jquery from "jquery"
window.$ = window.jQuery = jquery
window.bootstrap = require("bootstrap")

import "chartkick/chart.js"
import { Chart } from "chart.js"
import { getChartLabelPlugin } from "chart.js-plugin-labels-dv"
Chart.register(getChartLabelPlugin())

var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
  return new bootstrap.Tooltip(tooltipTriggerEl)
})

Turbo.session.drive = false
Rails.start()